var galleryTop = new Swiper('.gallery-top', {
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

$('.dropdown-menu a[href$="' + $(location).attr('pathname').substr(1) + '"]').addClass('active').parents('li.dropdown').addClass('active');
$('.navbar-nav a[href$="' + $(location).attr('pathname').substr(1) + '"].nav-link').addClass('active');

$(document).ready(function () {
    baguetteBox.run('.compact-gallery', {
      animation: 'slideIn',
      afterShow: () => {
        $('body').css('overflow', 'hidden');
      },
      afterHide: () => {
        $('body').css('overflow', '');
      }
    });
    baguetteBox.run('.grid-gallery', {
      animation: 'slideIn',
      afterShow: () => {
        $('body').css('overflow', 'hidden');
      },
      afterHide: () => {
        $('body').css('overflow', '');
      }
    });
});

function fullscreen() {
  var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);

  var docElm = document.documentElement;
  if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen();
      }
  } else {
      if (document.exitFullscreen) {
          document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
      }
  }
}
